import React from "react";

import ScrollTopBtn from "../common/scroll-top-btn/ScrollTopBtn";
import Navbar from "../navbar/Navbar";
import { Box } from "@mui/material";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet-async";

const AppTemplate = ({ title, children }) => {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <Navbar />
      <Box>{children}</Box>
      <Footer />
      <ScrollTopBtn />
    </>
  );
};

export default AppTemplate;
