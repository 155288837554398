import * as React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useEffect, useState } from "react";

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const scrollWithOffsetMobile = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const scrollWithOffsetHome = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 25
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <header className="header-section">
      <div className="container-fluid container-padding">
        <div className="row align-items-baseline">
          <div className="col-md-3 col-5">
            <Link to="/#home" scroll={scrollWithOffsetHome}>
              <img
                src={require("../../assets/img/logo.png")}
                className="d-none d-lg-inline-block d-md-inline-block d-sm-inline-block"
                alt="logo"
              />
            </Link>
            <Link to="/#home" scroll={scrollWithOffsetHome}>
              <img
                src={require("../../assets/img/logo.png")}
                className="d-lg-none d-md-none d-sm-none"
                alt="logo"
              />
            </Link>
          </div>
          <div className="col-md-9 col-7">
            <ul className="list-unstyled justify-content-end ul-desktop d-lg-flex d-md-none d-sm-none d-none">
              <li>
                <Link to="/#body-content" scroll={(el) => scrollWithOffset(el)}>
                  AnyTime, AnyWhere..
                </Link>
              </li>
              <li>
                <Link to="/our-story">Our Story</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/whitepaper">whitePaper</Link>
              </li>
              <li>
                <Link to="/contact-us">Set A meet</Link>
              </li>
            </ul>
            <div
              className="burger d-lg-none d-md-block d-sm-block d-block text-right"
              onClick={showSidebar}
            >
              <i className="fas fa-bars"></i>
            </div>
            <div className={sidebar ? "nav-menu active" : "nav-menu"}>
              <div onClick={showSidebar} className="icon-close">
                <i className="fas fa-times"></i>{" "}
              </div>
              <ul className="list-unstyled ul-mobile text-center mt-5">
                <li>
                  <Link
                    to="/#body-content"
                    scroll={(el) => scrollWithOffsetMobile(el)}
                  >
                    AnyTime, AnyWhere..
                  </Link>
                </li>
                <li>
                  <Link to="/our-story">Our Story</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/whitepaper">whitePaper</Link>
                </li>
                <li>
                  <Link to="/contact-us">Set A meet</Link>
                </li>
              </ul>
              {/* <div className="social">
                <Link to="" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link to="" target="_blank">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="" target="_blank">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Navbar;
