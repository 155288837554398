import ContactUs from "../pages/contact-us/ContactUs";
import FAQ from "../pages/faq/FAQ";
import Landing from "../pages/landing/Landing";
import OurStory from "../pages/our-story/OurStory";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import Services from "../pages/services/Services";
import Whitepaper from "../pages/whitepaper/Whitepaper";

export const homePage = [
  {
    title: "Zucodia",
    component: Landing,
    path: "/",
    exact: true,
  },
  {
    title: "Contact Us | Zucodia",
    component: ContactUs,
    path: "/contact-us",
    exact: true,
  },
  {
    title: "Services | Zucodia",
    component: Services,
    path: "/services",
    exact: true,
  },
  {
    title: "Our Story | Zucodia",
    component: OurStory,
    path: "/our-story",
    exact: true,
  },
  {
    title: "FAQ | Zucodia",
    component: FAQ,
    path: "/faq",
    exact: true,
  },
  {
    title: "Whitepaper | Zucodia",
    component: Whitepaper,
    path: "/whitepaper",
    exact: true,
  },
  {
    title: "Privacy Policy | Zucodia",
    component: PrivacyPolicy,
    path: "/privacy-policy",
    exact: true,
  },
];
