import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import TypeWriterEffect from "react-typewriter-effect";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { contactUsUrl } from "../../constants/endpoints";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ContactUs = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [SnackbarTitle, setSnackbarTitle] = useState("");
  const [SnackbarBackgroudColor, setSnackbarBackgroudColor] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("invalid email address")
      .required("please enter your email address"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      handleSubmitContactForm();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleSubmitContactForm = async () => {
    setLoadingBtn(true);
    const values = {
      name: formik.values.name,
      email: formik.values.email,
      content: formik.values.message,
    };
    await axios
      .post(contactUsUrl, values, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(function (response) {
        setSnackbarTitle(
          "Will contact you in the next 24 hours, see you soon."
        );
        setSnackbarBackgroudColor("#006539");
        setOpenSnackbar(true);
        formik.values.name = "";
        formik.values.email = "";
        formik.values.message = "";
      })
      .catch(function (error) {
        setSnackbarTitle(error.message);
        setSnackbarBackgroudColor("red");
        setOpenSnackbar(true);
      })
      .finally(function () {
        setLoadingBtn(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <div className="contact-page pages p-relative">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          // severity="success"
          sx={{ width: "100%", backgroundColor: SnackbarBackgroudColor }}
        >
          {SnackbarTitle}
        </Alert>
      </Snackbar>

      <div className="container-padding pb-5">
        <div className="row">
          <div className="col-lg-6 box-head">
            <h2>
              <TypeWriterEffect
                cursorColor="white"
                className="heroTextBig"
                typeSpeed={150}
                text="Contact Us"
                textStyle={{ color: "#038a50" }}
              />
            </h2>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  // value={name}
                  // onChange={handleChangeName}
                  {...getFieldProps("name")}
                />
                <TextField
                  id="outlined-basic"
                  type="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  // value={email}
                  // onChange={handleChangeEmail}
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  id="outlined-basic"
                  label="Write Your Message"
                  variant="outlined"
                  placeholder="Please specify a date and time up to your schedule, or any details you want."
                  fullWidth
                  rows={4}
                  multiline
                  {...getFieldProps("message")}

                  // value={message}
                  // onChange={handleChangeMessage}
                />
                <div className="text-center">
                  <LoadingButton
                    disabled={
                      Boolean(touched.email && errors.email) ||
                      formik.values.email === ""
                    }
                    loading={loadingBtn}
                    type="submit"
                    onClick={handleSubmitContactForm}
                    className="btn-send contactUsButton"
                    sx={{
                      "& 	.MuiCircularProgress-root": {
                        color: "#fff",
                      },
                    }}
                  >
                    Send Message
                  </LoadingButton>
                </div>
              </Form>
            </FormikProvider>
          </div>
          <img
            src={require("../../assets/img/10.png")}
            alt="Polygon"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
