import { useEffect } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import AOS from "aos";
import "aos/dist/aos.css";

const Whitepaper = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  return (
    <div className="about-page ">
      <div className="list">
        <img
          src={require("../../assets/img/Polygon4.png")}
          alt="Polygon"
          className="img-right d-lg-none d-md-block d-sm-block d-block"
          style={{ top: "0" }}
        />
      </div>
      <div className="container-padding">
        <div className="row">
          <div className="col-lg-7 col-md-9 box-head">
            <h2 /* data-aos="fade-up" data-aos-duration="1500" */>
              <span>
                <b>
                  <TypeWriterEffect
                    cursorColor="white"
                    className="heroTextBig"
                    typeSpeed={120}
                    text="Limit is the sky"
                    textStyle={{ color: "#038a50" }}
                  />
                </b>
              </span>
            </h2>
            <p /* data-aos="fade-down" data-aos-duration="3000" */>
              You know that Google was started on the 4th of September 1998. 8
              years later, its brand was added as a new verb to the English
              language when the Oxford Dictionary defined it as:<br></br>
              "to type words into the search engine Google® in order to find
              information about someone or something." – July 2006
              <br></br>
              <br></br>
              Google is the impeccable example to begin our WhitePaper to let
              you define the limits that you want for your business. We all know
              how the internet has become a basic need in our day-to-day lives;
              that’s why it’s crucial having your business as a part of the
              Internet world...
              <br></br>
              <br></br>
              Let's define together the essential concepts for any software in
              order to be an effective part of the internet with massive happy
              clients:
              <br></br>
              The first concept is UI/UX (User Interface/User Experience). UI/UX
              provides the best experience for your potential clients; Starting
              from Designs, logo, colours, user journey, Consistency,
              Friendliness, Clarity and last but not least, Transparency. I'll
              let Steve Jobs explain it in a few words: "Some people think
              design means how it looks. But of course, if you dig deeper, it's
              really how it works." – Steve Jobs.
              <br></br>
              <br></br>
              After having a good UI/UX, the next concept is developing your
              software. In other words, Coding a functional layout for your site
              and ensuring it's compatible with all browsers, mobile devices,
              and other devices that might access your software. Any programming
              technology could be used for your app. However, make sure to use
              the appropriate technology in the appropriate place in order to
              get the expected results. ”Software is the magic thing whose
              importance only goes up over time ” – Bill Gates.
              <br></br>
              <br></br>
              Our favorite concept appears at this step, QA(quality assurance).
              The importance of software testing is clearly seen in the final
              quality assurance report. To make the report, you should use good
              software quality assurance services. By successfully passing
              through the different levels of testing, the final product should
              perform as per the expected outcomes. At each level, the testers
              can not only figure out the errors but also prevent such
              errors/bugs in the future. Besides, exploring each mistake leads
              to the birth of an improvised version of the software.
              <br></br>
              <br></br>
              After all, we'd love to introduce the last but not the least
              concept, which is AI (Artificial Intelligence). AI is significant
              because it successfully tackles a profound set of technical
              challenges. Increasingly, human capabilities – understanding,
              reasoning, planning, communication and perception – can be
              undertaken by software, at scale and at low cost. General
              analytical tasks, including finding patterns in data that have
              been performed by software for many years, can also be performed
              more effectively using AI. Together, these capabilities create new
              opportunities in most business processes and consumer
              applications. <br></br>
              "Artificial Intelligence is the new electricity." – Andrew Ng.
              <br></br>
              <br></br>
              <br></br>
              Finally, we went over only the fundamental concepts, you should
              have an overview of what you need to do; if you need more
              information, just "Google it". If you don't have enough time for
              that, we would love to provide Zucodia services in any direction
              of software over the internet.
              <br></br>
              Please feel free to schedule a meeting, and let's build/improve
              together your business software, looking towards to touch your
              business Goals..
              <br></br>
              <br></br>
              <br></br>
              Zu Jah <br></br>
              CEO & Founder of Zucodia <br></br>
              12.12.2022
            </p>
          </div>
          <div className="col-lg-5">
            <img
              src={require("../../assets/img/5.png")}
              alt="Polygon"
              width="100%"
              className="img-sticky d-lg-block d-md-none d-sm-none d-none"
            />
          </div>
        </div>
      </div>
      <div className="list">
        <img
          src={require("../../assets/img/Polygon4.png")}
          alt="Polygon"
          className="img-right d-block d-lg-none"
        />
      </div>
    </div>
  );
};

export default Whitepaper;
