import { useEffect } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import AOS from "aos";
import "aos/dist/aos.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  return (
    <div>
      <div className="container-padding mb-5">
        <div className="row">
          <div className="col-lg-7 col-md-9 box-head">
            <h2 /* data-aos="fade-up" data-aos-duration="1500" */>
              <span>
                <b>
                  <TypeWriterEffect
                    cursorColor="white"
                    className="heroTextBig"
                    typeSpeed={120}
                    text="Privacy Policy"
                    textStyle={{ color: "#038a50" }}
                  />
                </b>
              </span>
            </h2>
            <p>
              PRIVACY NOTICE Last updated January 01, 2023
              <br />
              <br /> This privacy notice for Zucodia Tech SRL (doing business as
              Zucodia) (" Zucodia ," "we," "us," or "our" ), describes how and
              why we might collect, store, use, and/or share ("process") your
              information when you use our services ("Services"), such as when
              you: Visit our website at
              <a href="https://www.zucodia.com"> https://www.zucodia.com</a> ,
              or any website of ours that links to this privacy notice Engage
              with us in other related ways, including any sales, marketing, or
              events Questions or concerns? Reading this privacy notice will
              help you understand your privacy rights and choices.
              <br />
              <br /> If you do not agree with our policies and practices, please
              do not use our Services.
              <br />
              <br /> If you still have any questions or concerns, please contact
              us at info@zucodia.com .<br />
              <br /> SUMMARY OF KEY POINTS This summary provides key points from
              our privacy notice, but you can find out more details about any of
              these topics by clicking the link following each key point or by
              using our table of contents below to find the section you are
              looking for.
              <br />
              <br /> You can also <a href="#toc">click here</a> to go directly
              to our table of contents.
              <br />
              <br /> What personal information do we process? When you visit,
              use, or navigate our Services, we may process personal information
              depending on how you interact with Zucodia and the Services, the
              choices you make, and the products and features you use.
              <br />
              <br /> Click here to learn more.
              <br />
              <br /> Do we process any sensitive personal information? We do not
              process sensitive personal information.
              <br />
              <br /> Do we receive any information from third parties? We do not
              receive any information from third parties.
              <br />
              <br /> How do we process your information? We process your
              information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law.
              <br />
              <br /> We may also process your information for other purposes
              with your consent.
              <br />
              <br /> We process your information only when we have a valid legal
              reason to do so.
              <br />
              <br /> Click here to learn more. In what situations and with which
              parties do we share personal information? We may share information
              in specific situations and with specific third parties.
              <br />
              <br /> Click here to learn more. How do we keep your information
              safe? We have organizational and technical processes and
              procedures in place to protect your personal information.
              <br />
              <br /> However, no electronic transmission over the internet or
              information storage technology can be guaranteed to be 100%
              secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other unauthorized third parties will not be
              able to defeat our security and improperly collect, access, steal,
              or modify your information.
              <br />
              <br /> Click here to learn more. <br />
              <br />
              What are your rights? Depending on where you are located
              geographically, the applicable privacy law may mean you have
              certain rights regarding your personal information. Click here to
              learn more.
              <br />
              <br /> How do you exercise your rights? The easiest way to
              exercise your rights is by filling out our data subject request
              form available here: https://zucodia.com/contact-us , or by
              contacting us.
              <br />
              <br /> We will consider and act upon any request in accordance
              with applicable data protection laws.
              <br />
              <br /> Want to learn more about what Zucodia does with any
              information we collect? Click here to review the notice in full.
              <br />
              <br />
              <h3 id="toc">TABLE OF CONTENTS</h3>
              <ul>
                <li>WHAT INFORMATION DO WE COLLECT?</li>
                <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
                <li>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </li>
                <li>
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </li>
                <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                <li>
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                  <ul>
                    <li>
                      WHAT INFORMATION DO WE COLLECT?
                      <br /> Personal information you disclose to us In Short:
                      We collect personal information that you provide to us.
                      <br /> We collect personal information that you
                      voluntarily provide to us when you express an interest in
                      obtaining information about us or our products and
                      Services, when you participate in activities on the
                      Services, or otherwise when you contact us.
                      <br /> Personal Information Provided by You.
                      <br /> The personal information that we collect depends on
                      the context of your interactions with us and the Services,
                      the choices you make, and the products and features you
                      use.
                      <br /> The personal information we collect may include the
                      following: email addresses names Sensitive Information.
                      <br /> We do not process sensitive information. All
                      personal information that you provide to us must be true,
                      complete, and accurate, and you must notify us of any
                      changes to such personal information.
                    </li>
                    <li>
                      HOW DO WE PROCESS YOUR INFORMATION?
                      <br /> In Short: We process your information to provide,
                      improve, and administer our Services, communicate with
                      you, for security and fraud prevention, and to comply with
                      law.
                      <br /> We may also process your information for other
                      purposes with your consent.
                      <br /> We process your personal information for a variety
                      of reasons, depending on how you interact with our
                      Services, including: To save or protect an individual's
                      vital interest. <br />
                      We may process your information when necessary to save or
                      protect an individual’s vital interest, such as to prevent
                      harm.
                    </li>
                    <li>
                      WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                      INFORMATION?
                      <br /> In Short: We only process your personal information
                      when we believe it is necessary and we have a valid legal
                      reason (i.e. , legal basis) to do so under applicable law,
                      like with your consent, to comply with laws, to provide
                      you with services to enter into or fulfill our contractual
                      obligations, to protect your rights, or to fulfill our
                      legitimate business interests.
                      <br /> If you are located in the EU or UK, this section
                      applies to you.
                      <br /> The General Data Protection Regulation (GDPR) and
                      UK GDPR require us to explain the valid legal bases we
                      rely on in order to process your personal information.
                      <br /> As such, we may rely on the following legal bases
                      to process your personal information: Consent.
                      <br /> We may process your information if you have given
                      us permission (i.e. , consent) to use your personal
                      information for a specific purpose.
                      <br /> You can withdraw your consent at any time.
                      <br /> Click here to learn more. <br />
                      Legal Obligations.
                      <br /> We may process your information where we believe it
                      is necessary for compliance with our legal obligations,
                      such as to cooperate with a law enforcement body or
                      regulatory agency, exercise or defend our legal rights, or
                      disclose your information as evidence in litigation in
                      which we are involved.
                      <br /> Vital Interests.
                      <br />
                      We may process your information where we believe it is
                      necessary to protect your vital interests or the vital
                      interests of a third party, such as situations involving
                      potential threats to the safety of any person.
                    </li>
                    <li>
                      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                      <br /> In Short: We may share information in specific
                      situations described in this section and/or with the
                      following third parties.
                      <br /> We may need to share your personal information in
                      the following situations: Business Transfers.
                      <br /> We may share or transfer your information in
                      connection with, or during negotiations of, any merger,
                      sale of company assets, financing, or acquisition of all
                      or a portion of our business to another company.
                    </li>
                    <li>
                      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? In
                      Short: We may use cookies and other tracking technologies
                      to collect and store your information.
                      <br /> We may use cookies and similar tracking
                      technologies (like web beacons and pixels) to access or
                      store information.
                      <br /> Specific information about how we use such
                      technologies and how you can refuse certain cookies is set
                      out in our Cookie Notice.
                    </li>
                    <li>
                      HOW LONG DO WE KEEP YOUR INFORMATION?
                      <br /> In Short: We keep your information for as long as
                      necessary to fulfill the purposes outlined in this privacy
                      notice unless otherwise required by law.
                      <br />
                      We will only keep your personal information for as long as
                      it is necessary for the purposes set out in this privacy
                      notice, unless a longer retention period is required or
                      permitted by law (such as tax, accounting, or other legal
                      requirements).
                      <br /> When we have no ongoing legitimate business need to
                      process your personal information, we will either delete
                      or anonymize such information, or, if this is not possible
                      (for example, because your personal information has been
                      stored in backup archives), then we will securely store
                      your personal information and isolate it from any further
                      processing until deletion is possible.
                    </li>
                    <li>
                      HOW DO WE KEEP YOUR INFORMATION SAFE?
                      <br /> In Short: We aim to protect your personal
                      information through a system of organizational and
                      technical security measures.
                      <br /> We have implemented appropriate and reasonable
                      technical and organizational security measures designed to
                      protect the security of any personal information we
                      process.
                      <br /> However, despite our safeguards and efforts to
                      secure your information, no electronic transmission over
                      the Internet or information storage technology can be
                      guaranteed to be 100% secure, so we cannot promise or
                      guarantee that hackers, cybercriminals, or other
                      unauthorized third parties will not be able to defeat our
                      security and improperly collect, access, steal, or modify
                      your information.
                      <br /> Although we will do our best to protect your
                      personal information, transmission of personal information
                      to and from our Services is at your own risk.
                      <br /> You should only access the Services within a secure
                      environment.
                    </li>
                    <li>
                      DO WE COLLECT INFORMATION FROM MINORS? In Short: We do not
                      knowingly collect data from or market to children under 18
                      years of age.
                      <br /> We do not knowingly solicit data from or market to
                      children under 18 years of age.
                      <br /> By using the Services, you represent that you are
                      at least 18 or that you are the parent or guardian of such
                      a minor and consent to such minor dependent’s use of the
                      Services.
                      <br /> If we learn that personal information from users
                      less than 18 years of age has been collected, we will
                      deactivate the account and take reasonable measures to
                      promptly delete such data from our records.
                      <br /> If you become aware of any data we may have
                      collected from children under age 18, please contact us at
                      info@zucodia.com .
                    </li>
                    <li>
                      WHAT ARE YOUR PRIVACY RIGHTS? In Short: In some regions,
                      such as the European Economic Area (EEA) and United
                      Kingdom (UK) , you have rights that allow you greater
                      access to and control over your personal information.
                      <br /> You may review, change, or terminate your account
                      at any time.
                      <br /> In some regions (like the EEA and UK ), you have
                      certain rights under applicable data protection laws.
                      <br /> These may include the right (i) to request access
                      and obtain a copy of your personal information, (ii) to
                      request rectification or erasure; (iii) to restrict the
                      processing of your personal information; and (iv) if
                      applicable, to data portability.
                      <br /> In certain circumstances, you may also have the
                      right to object to the processing of your personal
                      information.
                      <br /> You can make such a request by contacting us by
                      using the contact details provided in the section " HOW
                      CAN YOU CONTACT US ABOUT THIS NOTICE? " below.
                      <br />
                      We will consider and act upon any request in accordance
                      with applicable data protection laws.
                      <br /> If you are located in the EEA or UK and you believe
                      we are unlawfully processing your personal information,
                      you also have the right to complain to your local data
                      protection supervisory authority.
                      <br /> You can find their contact details here:
                      <br />
                      https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                      .<br /> If you are located in Switzerland, the contact
                      details for the data protection authorities are available
                      here:
                      <br />
                      https://www.edoeb.admin.ch/edoeb/en/home.html .<br />{" "}
                      Withdrawing your consent: If we are relying on your
                      consent to process your personal information, which may be
                      express and/or implied consent depending on the applicable
                      law, you have the right to withdraw your consent at any
                      time.
                      <br /> You can withdraw your consent at any time by
                      contacting us by using the contact details provided in the
                      section " HOW CAN YOU CONTACT US ABOUT THIS NOTICE? "
                      below .<br />
                      However, please note that this will not affect the
                      lawfulness of the processing before its withdrawal nor,
                      when applicable law allows, will it affect the processing
                      of your personal information conducted in reliance on
                      lawful processing grounds other than consent.
                      <br /> Cookies and similar technologies: Most Web browsers
                      are set to accept cookies by default.
                      <br /> If you prefer, you can usually choose to set your
                      browser to remove cookies and to reject cookies.
                      <br /> If you choose to remove cookies or reject cookies,
                      this could affect certain features or services of our
                      Services. <br />
                      To opt out of interest-based advertising by advertisers on
                      our Services visit http://www.aboutads.info/choices/ .
                      <br /> If you have questions or comments about your
                      privacy rights, you may email us at info@zucodia.com .{" "}
                    </li>
                    <li>
                      CONTROLS FOR DO-NOT-TRACK FEATURES Most web browsers and
                      some mobile operating systems and mobile applications
                      include a Do-Not-Track ( "DNT" ) feature or setting you
                      can activate to signal your privacy preference not to have
                      data about your online browsing activities monitored and
                      collected. <br /> At this stage no uniform technology
                      standard for recognizing and implementing DNT signals has
                      been finalized . <br /> As such, we do not currently
                      respond to DNT browser signals or any other mechanism that
                      automatically communicates your choice not to be tracked
                      online. <br /> If a standard for online tracking is
                      adopted that we must follow in the future, we will inform
                      you about that practice in a revised version of this
                      privacy notice.
                    </li>
                    <li>
                      DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? In
                      Short: Yes, if you are a resident of California, you are
                      granted specific rights regarding access to your personal
                      information.
                      <br /> California Civil Code Section 1798.83, also known
                      as the "Shine The Light" law, permits our users who are
                      California residents to request and obtain from us, once a
                      year and free of charge, information about categories of
                      personal information (if any) we disclosed to third
                      parties for direct marketing purposes and the names and
                      addresses of all third parties with which we shared
                      personal information in the immediately preceding calendar
                      year.
                      <br /> If you are a California resident and would like to
                      make such a request, please submit your request in writing
                      to us using the contact information provided below.
                      <br /> If you are under 18 years of age, reside in
                      California, and have a registered account with Services,
                      you have the right to request removal of unwanted data
                      that you publicly post on the Services.
                      <br /> To request removal of such data, please contact us
                      using the contact information provided below and include
                      the email address associated with your account and a
                      statement that you reside in California.
                      <br /> We will make sure the data is not publicly
                      displayed on the Services, but please be aware that the
                      data may not be completely or comprehensively removed from
                      all our systems (e.g. , backups, etc.).
                    </li>
                    <li>
                      DO WE MAKE UPDATES TO THIS NOTICE? In Short: Yes, we will
                      update this notice as necessary to stay compliant with
                      relevant laws.
                      <br /> We may update this privacy notice from time to
                      time.
                      <br /> The updated version will be indicated by an updated
                      "Revised" date and the updated version will be effective
                      as soon as it is accessible.
                      <br />
                      If we make material changes to this privacy notice, we may
                      notify you either by prominently posting a notice of such
                      changes or by directly sending you a notification.
                      <br /> We encourage you to review this privacy notice
                      frequently to be informed of how we are protecting your
                      information.
                    </li>
                    <li>
                      HOW CAN YOU CONTACT US ABOUT THIS NOTICE? <br />
                      If you have questions or comments about this notice, you
                      may email us at info@zucodia.com or by post to: Zucodia
                      Tech SRL Baba Dochia Timisoara , Timis 300118 Romania
                    </li>
                    <li>
                      HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                      FROM YOU?
                      <br /> Based on the applicable laws of your country, you
                      may have the right to request access to the personal
                      information we collect from you, change that information,
                      or delete it.
                      <br /> To request to review, update, or delete your
                      personal information, please visit:
                      https://zucodia.com/contact-us .<br /> This privacy policy
                      was created using Termly's Privacy Policy Generator.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
          <div className="col-lg-5">
            <img
              src={require("../../assets/img/5.png")}
              alt="Polygon"
              width="100%"
              className="img-sticky d-lg-block d-md-none d-sm-none d-none"
            />
          </div>
        </div>
      </div>
      {/* <div className="list">
        <img
          src={require("../../assets/img/Polygon4.png")}
          alt="Polygon"
          className="img-right d-block"
        />
      </div> */}
    </div>
  );
};

export default PrivacyPolicy;
