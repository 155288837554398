import { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import TypeWriterEffect from "react-typewriter-effect";
import AOS from "aos";
import "aos/dist/aos.css";
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import globalVideo from "../../assets/video/global-network-connection-2022-04-25-12-20-58-utc.mp4";
const Landing = () => {
  // const [expanded, setExpanded] = useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  // const scrollWithOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -180;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  // };

  return (
    <div className="intro pages" id="home">
      {/* intro-video section */}
      <div className="intro-banner" id="main-content">
        <div className="banner-video">
          <div className="overlay-content"></div>

          {/* <video
            className="binary-video"
            src={require("../../assets/video/global-network-connection-2022-04-25-12-20-58-utc.mp4")}
            width="100%"
            height="500"
            autoPlay
            muted
            loop
          ></video> */}
          <img
            className=" global-placeholder w-100"
            // className="w-100"
            src={require("../../assets/video/global-placeholder.png")}
            alt="zucodia.com"
          />
        </div>
        <div className="content">
          <TypeWriterEffect
            cursorColor="white"
            className="heroTextBig"
            typeSpeed={150}
            text="Zucodia"
          />
          <p>
            {/* AnyWhere.. AnyTime.. */}
            {/* Is <strong>software</strong> important to your Business?<br></br>
            Do you have just an <strong>idea</strong> ?<br></br>
            let's discover it and make an <strong>
              Inteligent software
            </strong>{" "}
            Together */}
          </p>
          {/* <Link to="/contact-us" className="link-meet">
            Schedual a meet
          </Link> */}
          {/* <div className="mt-5">
            {" "}
            <Link to="/#body-content" scroll={(el) => scrollWithOffset(el)}>
              <i className="far fa-angle-double-down"></i>
            </Link>
          </div> */}
        </div>
        <div className="bg1"></div>
      </div>
      <div className="intro-content">
        {/* mission section */}
        <div
          className="container position-relative"
          data-aos="zoom-in-up"
          data-aos-duration="1500"
        >
          <div className="mission-section">
            {/* <h2>Our Mission</h2> */}
            <div className="row justify-content-center">
              <div className="col-lg-10 mb-5">
                <p className="text-justify">
                  <h2>Unlock Your Business's with Zucodia</h2>
                  <li>
                    Zucodia is a platform that provides everything you need to
                    create powerful and user-friendly software applications by
                    offering and developing customized business development
                    solutions.{" "}
                  </li>
                  <li>
                    We understand that every business is unique, which is why we
                    take a customized approach to every project. We work closely
                    with you to understand your business's specific needs and
                    goals and develop a tailored plan that's designed to deliver
                    results.
                  </li>
                  <li>
                    Ready to take your business to the next level? Contact us
                    today to schedule a free consultation and learn more about
                    how we can support you to Achieve Your Business Goals.
                  </li>
                </p>
              </div>
            </div>
            <div className="row justify-content-center row-respo margin-top-7">
              <div className="col-md-4 col-sm-4 col-4 text-center col-respo small">
                <div className="box box1">
                  <div className="box-img">
                    <img src={require("../../assets/img/1.png")} alt="laptop" />
                  </div>
                  <h5>WEB & Mobile APP</h5>
                  <div className="line"></div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-4 text-center shape1 col-respo small">
                <div className="box-shadow">
                  <div className="overlay"></div>
                  <img src={require("../../assets/img/3.png")} alt="laptop" />
                  <div className="line-respo line-respo1"></div>
                  <div className="line-respo line-respo2"></div>
                  <div className="line-respo3"></div>
                  <div className="line-respo4"></div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-4  text-center col-respo small">
                <div className="box box2">
                  <div className="line"></div>
                  <div className="box-img">
                    <img src={require("../../assets/img/2.png")} alt="search" />
                  </div>
                  <h5>QA Service</h5>
                  <div className="line2"></div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-4  text-center small">
                <div className="box box3">
                  <div className="line"></div>
                  <div className="box-img">
                    <img src={require("../../assets/img/4.png")} alt="ai" />
                  </div>
                  <h5>Artificial intelligence</h5>
                  <div className="line2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* video section */}
        <div
          className="banner-video banner2 position-relative"
          id="body-content"
        >
          {/* <img
            src={require("../../assets/img/Polygon3.png")}
            alt="Polygon3"
            className="position-absolute Polygon3Image"
          /> */}
          <div className="overlay-content-black"></div>

          <video
            className="binary-video w-100"
            src={require("../../assets/video/binary-matrix-2022-08-04-03-02-22-utc.mp4")}
            autoPlay
            muted
            loop
            // poster={require("../../assets/img/poster/binaryPoster.png")}
          ></video>
          <img
            className="binary-placeholder"
            src={require("../../assets/video/binary-placeholder.png")}
            alt="zucodia.com"
          />
          <div className="position-absolute d-flex flex-column justify-content-center align-items-center weThinkTextBox w-100 text-center">
            <div
              className="d-flex"
              data-aos="zoom-in-down"
              data-aos-duration="1500"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="box-hover">
                <img
                  src={require("../../assets/img/weThink/weT.png")}
                  alt="we"
                  className="img-hover"
                />
                <img
                  src={require("../../assets/img/weThink/img-hover.png")}
                  alt="we"
                  className="WeTImg"
                />
                <img
                  src={require("../../assets/img/weThink/HINK.png")}
                  alt="think"
                />
              </div>
            </div>
            <h4 className="text-white">Different</h4>
            <h5 className=" weThinkParagraph text-center">
              <TypeWriterEffect
                startDelay={100}
                cursorColor="white"
                typeSpeed={50}
                text="We're waiting for you to disscuss your business needs."
                hideCursorAfterText={true}
              />{" "}
              <Link to="/contact-us">
                <TypeWriterEffect
                  startDelay={100}
                  cursorColor="white"
                  typeSpeed={250}
                  text="Let's make actions"
                  hideCursorAfterText={true}
                />
              </Link>{" "}
              <TypeWriterEffect
                startDelay={100}
                cursorColor="white"
                typeSpeed={250}
                text="more than bits!"
                className="thinkDeffDescPart1"
              />
              {/* <b>
                It's possible to meet your business goals with the help of an
                appropriate software which know how to be closer to your
                existing and potenbtial clients.
              </b> */}
            </h5>
            <h5 className="weThinkParagraphSmallSize text-center">
              We're waiting for your to disscuss your business needs.{" "}
              <b>
                <Link to="/contact-us">Let's make actions</Link>
              </b>{" "}
              more than bits!
            </h5>
          </div>
        </div>
        {/* service section */}
        <div className="service-section">
          <div className="container-fluid container-padding">
            <div className="row service-section-flex">
              <div className="col-lg-4 "></div>
              <div className="col-lg-4">
                <div className="line"></div>
                <div className="box-img">
                  <img
                    src={require("../../assets/img/Group 123.png")}
                    alt="Polygon"
                  />
                </div>
                <div className="line line2"></div>
                <div className=" line3"></div>
                <h2>Your software is ready to meet your clients!</h2>
                <div className=" line3 line4"></div>
                <div className=" line line5"></div>
              </div>
              <div className="col-lg-4 col-serv">
                <div className="title-serv design">
                  <TypeWriterEffect
                    cursorColor="white"
                    className="design"
                    typeSpeed={150}
                    hideCursorAfterText={true}
                    text="Design"
                  />
                </div>
                <div className="desc">
                  <li>Our Scheduled Meeting</li>
                  <li>Software Model</li>
                  <li>User Interface</li>
                </div>
                <div className="title-serv Development">
                  <TypeWriterEffect
                    cursorColor="white"
                    className="Development"
                    typeSpeed={150}
                    hideCursorAfterText={true}
                    text="Development"
                  />
                </div>
                <div className="desc">
                  <li>Software Development</li>
                  <li>User experience</li>
                  <li>Beta Software</li>
                </div>
                <div className="title-serv QA">
                  <TypeWriterEffect
                    cursorColor="white"
                    className="QA"
                    hideCursorAfterText={true}
                    typeSpeed={150}
                    text="QA"
                  />
                </div>
                <div className="desc">
                  <li>Test plans</li>
                  <li>Manual/Automated Tests</li>
                  <li>Demo software</li>
                </div>
              </div>
            </div>
          </div>
          <div className="banner3">
            <img
              src={require("../../assets/img/5.png")}
              alt="img"
              width="100%"
            />
          </div>
        </div>
        {/* about section */}
        <div className="about-section">
          <div className="container-fluid container-padding">
            <div className="row align-items-center">
              <div className="col-lg-6 ">
                <h1>
                  <div className="co-bg">
                    {" "}
                    <img
                      src={require("../../assets/img/6.png")}
                      alt="img"
                      width="100%"
                    />
                  </div>
                  Why <br />
                  Hire <br />
                  <span data-aos="zoom-in-right" data-aos-duration="1500">
                    Zucodia
                  </span>
                  <br /> Services?
                </h1>
              </div>
              <div className="col-lg-6 pr-0 pl-5 ulList">
                <ul>
                  <li>End to end delivery.</li>
                  <li>
                    Professional, scalable and well QA-
                    <span style={{ textTransform: "lowercase" }}>ed</span>{" "}
                    Applications.
                  </li>
                  <li>Demo version and free year of support.</li>
                  <li>Distinct Payment options.</li>
                  <li>Last but not least ... Always meet deadlines.</li>
                </ul>
                <br></br>
                <Link
                  to="/contact-us"
                  // data-aos="zoom-out-up"
                  // data-aos-duration="1500"
                >
                  Schedual a meet
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* faq section */}
        {/* <div className="faq-section" id="faq">
          <div className="container-fluid container-padding">
            <h2>FAQ </h2>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h5>How this work?</h5>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Yet bed any for assistance indulgence unpleasing. Not thoughts
                  all exercise blessing. Indulgence way everything joy
                  alteration boisterous the attachment.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <h5>Are there any additional fee?</h5>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Donec placerat, lectus sed mattis semper, neque lectus feugiat
                  lectus, varius pulvinar diam eros in elit. Pellentesque
                  convallis laoreet laoreet.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <h5>How can I get the app?</h5>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Donec placerat, lectus sed mattis semper, neque lectus feugiat
                  lectus, varius pulvinar diam eros in elit. Pellentesque
                  convallis laoreet laoreet.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <h5>What features do you offer and other not?</h5>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Donec placerat, lectus sed mattis semper, neque lectus feugiat
                  lectus, varius pulvinar diam eros in elit. Pellentesque
                  convallis laoreet laoreet.
                </p>
              </AccordionDetails>
            </Accordion>
            <div className="text-center link">
              <Link to="">
                More FAQs <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div> */}
        <div
          className="faq-section"
          // data-aos="zoom-in-up"
          // data-aos-duration="2000"
        >
          <div className="text-center link  ">
            <Link to="/faq">
              More FAQs <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
