import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HashLink as Link } from "react-router-hash-link";
import TypeWriterEffect from "react-typewriter-effect";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [expanded, setExpanded] = useState("panel7");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="about-page">
      <div className="faq-section" id="faq">
        <div className="container-fluid container-padding ">
          <h2>
            <TypeWriterEffect
              cursorColor="white"
              className="heroTextBig"
              typeSpeed={150}
              text="FAQ"
            />
          </h2>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h5 className={expanded === "panel7" ? "logo-text-color" : ""}>
                How your business will use AI?
              </h5>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                <b>Identifying AI use case</b>
                <br></br>
                Working closely with your company, we will identify the key
                areas where AI can bring the most value. This involves meetings
                with all stakeholders and developing a roadmap for action
                together.
                <br></br>
                <br></br>
                <b>Data exploration and proof of concept</b>
                <br></br>
                We will evaluate if you have the business data needed to train
                relevant machine learning models. If required, we identify
                additional frameworks for data collection in your company.
                <br></br>
                <br></br>
                <b>Proposal for deliverables</b>
                <br></br>
                Based on our meetings and data analysis, we’ll share with you
                the possible AI use cases for your company. We will work
                hand-in-hand to agree on the desired outcome.
                <br></br>
                <br></br>
                <b>Model development</b>
                <br></br>
                We will build and apply various machine learning models to your
                business data, to find the best solution. As a result, we will
                develop algorithms that accomplish the desired goal.
                <br></br>
                <br></br>
                <b>Application deployment</b>
                <br></br>
                We integrate the machine learning model with an API or front-end
                product, making it user-friendly and accessible to the end-user.{" "}
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h5 className={expanded === "panel1" ? "logo-text-color" : ""}>
                How can I get a software for my business?
              </h5>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                You are a click away from building your own software based on
                your business needs. Send us a message, and we will be in touch
                in the next 24 hours.<br></br>
                Just an{" "}
                <Link to="/contact-us" className="faqAnswerAchor">
                  email
                </Link>{" "}
                apart.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <h5 className={expanded === "panel2" ? "logo-text-color" : ""}>
                How much time does it take to build a software?
              </h5>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                It depends on the business requirement. However, we will set a
                set of deadlines at each development level.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <h5 className={expanded === "panel3" ? "logo-text-color" : ""}>
                Why hire zucodia?
              </h5>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>End to end delivery.</li>
                <li>Professional, scalable and well QA-ed Applications.</li>
                <li>Demo version and free year of support.</li>
                <li>Distinct Payment options.</li>
                <li>Last but not least ... Always meet deadlines.</li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <h5 className={expanded === "panel4" ? "logo-text-color" : ""}>
                Why UI/UX is important?
              </h5>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Maybe the best person who answered this was Steve Jobs when he
                said: <br></br>
                "Some people think design means how it looks. But of course, if
                you dig deeper, it's really how it works.".
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h5 className={expanded === "panel5" ? "logo-text-color" : ""}>
                Why QA services are important?
              </h5>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                The importance of software testing is clearly seen in the final
                quality assurance report. To make the report, you should use
                good software quality assurance services. By successfully
                passing through the different levels of testing, the final
                product should perform as per the expected outcomes. At each
                level, the testers can not only figure out the errors but also
                prevent such errors/bugs in the future. Besides, exploring each
                mistake leads to the birth of an improvised version of the
                software.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h5 className={expanded === "panel6" ? "logo-text-color" : ""}>
                Why AI is important?
              </h5>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                AI successfully tackles a profound set of technical challenges.
                Increasingly, human capabilities – understanding, reasoning,
                planning, communication and perception – can be undertaken by
                software, at scale and at low cost. General analytical tasks,
                including finding patterns in data that have been performed by
                software for many years, can also be performed more effectively
                using AI. Together, these capabilities create new opportunities
                in most business processes and consumer applications. <br></br>
                "Artificial Intelligence is the new electricity." – Andrew Ng.
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
