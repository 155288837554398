import { useEffect } from "react";
import { Link } from "react-router-dom";
import TypeWriterEffect from "react-typewriter-effect";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="service-page pages">
      <div className="container-padding pb-5">
        <div className="row align-items-center row-grid">
          <div className="col-lg-7">
            <h2 className="mb-3" data-aos="zoom-in-up" data-aos-duration="1500">
              <TypeWriterEffect
                cursorColor="white"
                className="heroTextBig"
                typeSpeed={90}
                text="WEB & Mobile APP"
                textStyle={{ color: "#038a50" }}
              />
            </h2>
            <p data-aos="zoom-in-down" data-aos-duration="1500">
              DataArt UI and UX should smoothly integrate into the software
              development process to work on convenient and intuitive digital
              products. We follow modern trends and work hand-in-hand with the
              development team to create the most effective software, starting
              from UX/UI solutions for web, iOS, and Android. Once the UI/UX
              design is done, the actual services start:
              <li>
                Software Development - programmed on the latest technologies.
              </li>
              <li>
                Hosting (unlimited space, unlimited bandwidth, unlimited email
                accounts) on a powerful & secure dedicated server, shared
                resources.
              </li>
              <li>
                Technical assistance (technical support team, site errors,
                emails) - free first 6 months.
              </li>
              <li>Self-generated SSL certificate.</li>
              <li>Advanced statistics.</li>
            </p>
          </div>
          <div className="col-lg-5">
            <div
              className="box-img"
              data-aos="zoom-in-right"
              data-aos-duration="1500"
            >
              <img
                src={require("../../assets/img/Code.png")}
                alt="code"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="row align-items-center row-grid">
          <div className="col-lg-7">
            <h2 className="mb-3">
              <TypeWriterEffect
                cursorColor="white"
                className="heroTextBig"
                typeSpeed={60}
                text="Quality assurance (QA)"
                textStyle={{ color: "#038a50" }}
              />
            </h2>
            <p>
              QA services help you deliver quality software and to provide your
              end customers with the best experience. We are high achievers who
              make sure your product works perfectly and improves your
              customers’ life. During manual testing, our team checks the
              performance of your product on various devices, as well as
              analyzes its user interface and usability.<br></br>
              Manual testing helps to identify vulnerabilities that can’t be
              detected automatically and provides personal feedback about the
              look and feel of your product. <br></br>
              On the other side Automated testing is a long-term investment in
              the success of your product. It covers all possible scenarios of
              software functionality and then checks its performance on multiple
              platforms in parallel. By combining both manual and automated
              testing, you accelerate your time to market and satisfy your
              customers with a flawless product.
            </p>
          </div>
          <div className="col-lg-5">
            <div className="box-img">
              <img
                src={require("../../assets/img/8.png")}
                alt="Quality-assurance"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="row align-items-center row-grid">
          <div className="col-lg-7 ">
            <h2 className="mb-3">
              <TypeWriterEffect
                cursorColor="white"
                className="heroTextBig"
                typeSpeed={60}
                text="Artificial intelligence (AI)"
                textStyle={{ color: "#038a50" }}
              />
            </h2>
            <p>
              {" "}
              Run, maintain and scale AI and machine learning solutions from
              pilot to production. Adopt frameworks to leverage real-time data
              for developing and deploying AI applications in a real-world
              context and drive a positive ROI. we develop, train, test and
              integrate machine learning algorithms to optimize and accelerate
              the systems and platforms. <br></br>
              Automate document processing, improve customer service, chatbots,
              understand the root cause of anomalies, and extract insights from
              any content—all with AI services. <br></br> Modernize your
              business processes faster with AI Services and be ready for the
              tools, services, and guidelines to help you use AI responsibly,
              while also preserving data privacy, transparency, and trust.
              <br></br>
              Is your software ready?
            </p>
          </div>
          <div className="col-lg-5">
            <div className="box-img">
              <img
                src={require("../../assets/img/9.png")}
                alt="Artificial-intelligence"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="contact-box ">
          <div className="row align-items-center">
            <div className="col-lg-8 ">
              <h3>Anytime.. Anywhere.. An email apart.. </h3>
            </div>
            <div className="col-lg-4 contactUsButton">
              <Link to="/contact-us">Schedual a meet</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
