import { Box } from "@mui/material";
import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
// import { Button } from "./Styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const ScrollTopBtn = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const theme = useTheme();
  const mediaQuery600 = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        left: mediaQuery600 ? "90%" : "95%",
        bottom: "40px",
        height: "20px",
        fontSize: "2rem",
        zIndex: 999999,
        cursor: "pointer",
      }}
    >
      <FaArrowCircleUp
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </Box>
  );
};

export default ScrollTopBtn;
