import { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import TypeWriterEffect from "react-typewriter-effect";
import AOS from "aos";
import "aos/dist/aos.css";

const OurStory = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-page">
      <div className="container-padding">
        <div className="row  box-head">
          <div className="col-lg-7  ">
            <h2>
              <span>
                <b>
                  <TypeWriterEffect
                    cursorColor="white"
                    hideCursorAfterText={true}
                    typeSpeed={150}
                    textStyle={{ color: "#038a50" }}
                    text="Our Idea"
                  />
                </b>
              </span>
            </h2>
            <p>
              Zucodia was born in 2021 out of one man's dream to help other
              businesses be a part of the internet efficiently. So We set out to
              transform the way we interact with online software, by controlling
              every step of the process from A to Z, to get the highest quality,
              biggest selection and most consistent software experience.
              <br></br>
              With online software solutions for Bussiness at every level from
              solopreneurs to enterprise level at any direction of online
              software, Zucodia will continue to strive toward our vision of
              Online Software & training in every online Business' toolkit.
            </p>
          </div>
          <div className="col-lg-5 ">
            <img
              src={require("../../assets/img/idea.png")}
              alt="idea"
              width="100%"
            />
          </div>
        </div>
        <div className="row box-head align-items-center">
          <div className="col-lg-5 ">
            <img
              src={require("../../assets/img/goal.png")}
              alt="goal"
              width="100%"
            />
          </div>
          <div className="col-lg-7 ">
            <h2>
              <span>
                <b>
                  <TypeWriterEffect
                    cursorColor="white"
                    textStyle={{ color: "#038a50" }}
                    hideCursorAfterText={true}
                    typeSpeed={150}
                    text="Our Goal"
                  />
                </b>
              </span>
            </h2>
            <p>
              Endeavour toward your business needs by taking advantage of
              Zucodia services for your new/existing business at the most
              affordable prices. Starting from User Interface (UI), passing
              throw User Experience, Development, QA and last but not least AI
              services.
            </p>

            <h2>
              <span>
                <b>
                  <TypeWriterEffect
                    cursorColor="white"
                    typeSpeed={150}
                    textStyle={{ color: "#038a50" }}
                    hideCursorAfterText={true}
                    text="Our Culture"
                  />
                </b>
              </span>
            </h2>
            <p>
              Meet Deadlines & Respect Our Word.<br></br>
              This is the Zucodia Way.
            </p>
          </div>
        </div>
      </div>
      <div className="list">
        <img
          src={require("../../assets/img/Polygon4.png")}
          alt="Polygon"
          className="img-right"
        />
        {/* <div className="container-padding">
          <div className="box-list">
            <div className="border-top"></div>
            <span className="chevron top"></span>
            <div className="row align-items-center">
              <div className="col-md-3 text-center">
                <img src={require("../../assets/img/7.png")} alt="web" />
                <p> WEB & Mobile APP</p>
              </div>
              <div className="col-md-9">
                <h5>
                  A Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknownA
                  Lorem Ipsum is simply dummy text of{" "}
                </h5>
              </div>
            </div>
            <div className="border-bottom"></div>
          </div>
          <div className="box-list">
            <div className="border-top"></div>
            <span className="chevron top"></span>
            <div className="row align-items-center">
              <div className="col-md-3 text-center">
                <img src={require("../../assets/img/7.png")} alt="web" />
                <p> WEB & Mobile APP</p>
              </div>
              <div className="col-md-9">
                <h5>
                  A Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknownA
                  Lorem Ipsum is simply dummy text of{" "}
                </h5>
              </div>
            </div>
            <div className="border-bottom"></div>
          </div>
          <div className="box-list">
            <div className="border-top"></div>
            <span className="chevron top"></span>
            <div className="row align-items-center">
              <div className="col-md-3 text-center">
                <img src={require("../../assets/img/7.png")} alt="web" />
                <p> WEB & Mobile APP</p>
              </div>
              <div className="col-md-9">
                <h5>
                  A Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknownA
                  Lorem Ipsum is simply dummy text of{" "}
                </h5>
              </div>
            </div>
            <div className="border-bottom"></div>
          </div>
        </div> */}
        {/* <img
          src={require("../../assets/img/Polygon4.png")}
          alt="Polygon"
          className="img-left"
        /> */}
      </div>

      {/* <div className="container-padding ">
        <div className="row row-bottom mb-5 pb-5 align-items-center ">
          <div className="col-lg-8">
            <h2><span>Your</span> Single Step </h2>
            <p>Let's make actions more than words... We're waiting for our next meeting, an email apart at  sales@zucodia.com</p>
          </div>
          <div className="col-lg-4 mt-4 ">
            <Link to="/contact-us">Schedual a meet</Link>
          </div>
        </div>
      </div> */}
      <div className="container-padding ">
        <div className="contact-box  ourStoryContactBox">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-8 ">
              <h2>
                <span>Your</span> Single Step..{" "}
              </h2>
              {/* <p>
                Let's make actions more than words... We're waiting for our next
                meeting, an email apart at sales@zucodia.com
              </p> */}
            </div>
            <div className=" contactUsButton">
              <Link to="/contact-us">Schedual a meet </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
