import { Link } from "react-router-dom";
import TypeWriterEffect from "react-typewriter-effect";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid container-padding">
        <div className="row">
          <div className="col-lg-5 col-md-7 col-sm-9 col-10">
            <Link to="/">
              <img
                src={require("../../assets/img/logo-footer.png")}
                alt="logo"
                className="img-logo"
              />
            </Link>
            <div className="d-flex justify-content-between list-footer">
              <ul className="list-unstyled">
                <h6>About Us</h6>
                <li>
                  <Link to="/our-story">Our Story</Link>
                </li>
                <li>
                  <Link to="/whitepaper">Whitepaper</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
              </ul>
              <ul className="list-unstyled">
                <h6>Support</h6>
                <li>
                  <Link to="/contact-us">Conatct Us</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            {/* <div className="social">
              <Link to="" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="" target="_blank">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="" target="_blank">
                <i className="fab fa-instagram"></i>
              </Link>
            </div> */}
            <p>All rights reserved@2022</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
