import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppTemplate from "./components/app-template";
import { routes } from "./routes";
import './assets/css/style.css';
function App() {
  return (
    <div className="App" id="app">
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          {" "}
          {routes.map((route, index) => (
            <Route exact={route.exact} path={route.path} key={index}>
              <AppTemplate title={route.title}>
                <route.component />
              </AppTemplate>
            </Route>
          ))}
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
